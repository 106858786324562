main {
    font-family: 'Lucida Sans Unicode';
    max-width: 1024px;
    margin: 115px auto;
}

.page-title {
    margin-bottom: 60px;
}

.info_links {
    display: flex;
    background-color: #F9F9F9;
    box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    padding: 10px;
    margin-bottom: 16px;
}

.info_links svg {
    font-size: 20px;
    margin: 5px 15px 0 0;
}

.info_links p {
    font-size: 20px;
    color: #333;
}

.publicacao-container {
    margin: 40px 0;
}

.publicacao-year-title {
    margin-bottom: 40px;
}

.collapse-container {
    display: flex;
    gap: 40px;
}