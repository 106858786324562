ul, li, p {
	list-style: none;
	font-family: Arial;
}

a {
	text-decoration: none;
	color: white;
}

.container_ {
	padding: 3% 0;
	
	border-bottom: 6px solid #D60000;
}

.inner_container {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;

	max-width: 1024px;
	margin: auto;
}

.logos {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	max-width: 160px;
}

a #logo2 {
	display: none;
}

a:hover #logo {
	animation-delay: 0.1s;
	animation: logoanimation 0.3s ease-out forwards;
}

a:hover #logo2 {
	display: block;
	transform: translateX(-30px);
}

@keyframes logoanimation {
	from {
		opacity: 100%;
		display: block;
		transform: translateX(-10px);
	}

	to {
		opacity: 0%;
		display: none;
		transform: translateX(-50px);
	}
}

.social_media {
	margin-left: 25px;
	max-width: 150px;
	display: flex;
	align-items: center;
	justify-content: right;
	font-size: 25px;
}

.social_media_icon {
	width: 26px;
	transition: translateY 1s;
	color: #222;
	margin-left: 25px;
    transform: scale(1);
    transition: ease all 0.2s;
}

.social_media_icon:hover {
    transform: scale(1.03);
    transition: ease all 0.2s;
	color: #D60000;
}

.social_media_icon:hover img {
    transform: scale(1.03);
    transition: ease all 0.2s;
    filter: invert(11%) sepia(99%) saturate(5177%) hue-rotate(4deg) brightness(87%) contrast(117%);
}

/*Menu*/
#menu_header {
	display: flex;
	justify-content: center;
	align-items: center;
}

#menu_header li a {
	font-size: 14px;

	display: block;
	text-align: center;

	padding-left: 25px;
	
	color: #333333;
}

#menu_header ul li {
	position: relative;
	display: none;
}

#menu_header li:hover li {
	display: block;
}

#menu_header a:hover {
	color: #D60000;
	text-decoration: none;
}

#menu_header ul {
	position: absolute;
	transform: translateX(-40px);
}

#menu_header ul li {
	border-bottom: solid 1px #ccc;
}

#menu_header ul li a {
	background-color: white;
	text-align: center;
	padding: 7px;
	box-shadow: 2px 0px 1px rgba(50, 50, 50, 0.77);
}
