.card-container {
    height: auto;
    width: 1130px;
    padding: 18px 30px;
    margin-bottom: 32px;

    background-color: #F9F9F9;
    box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
}

.card-picture {
    width: 224px;
    height: 224px;
}

.card-picture img {
    box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
}

.card-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    font-size: 16px;
}

.card-name {
    font-weight: 400px;
    color: #222;
}

.card-link {
    height: 37px;
}

.card-link {
    margin-left: 24px;
    
    transform: scale(1);
    transition: ease all 0.5s;
}

.card-link:hover, .card-link:hover * {
    filter: invert(14%) sepia(84%) saturate(4838%) hue-rotate(2deg) brightness(86%) contrast(123%);
    transition: ease all 0.5s;
}

.card-description {
    font-size: 16px;
}