.timeline {
    display: flex;
    justify-content: center;
    align-items: center;
}

.timelineFullPoint {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin: 10px;
    color: #222;
    text-decoration: none;
}

.timelineFullPoint:hover {
    color: #222;
    text-decoration: none;
}

.yearPoint {
    margin: 5px;
    height: 25px;
}

.point {
    display: flex;
    justify-content: center;
    align-items: center;

    border: 1px solid #222;
    padding: 4px;
    border-radius: 50%;
    width: 24px;
    height: 24px;
}

.point:hover {
    transition: all .2s ease-in;
    color: darkslategray;
}

.information {
    list-style: circle;
}