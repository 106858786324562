.container {
    max-width: 1024px;
    margin: 110px auto;
}

.title-section {
    font-size: 24px;
    margin-bottom: 32px;
    color: #222;
}

.role-section {
    margin-bottom: 80px;
}

.names-container {
    padding: 15px;
    background-color: #F9F9F9;
    box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
}