.home_main {
    display: flex;
    flex-direction: column;

    margin: 65px auto;
    max-width: 1024px;

    align-items: center;
    justify-content: center;
}

#titulo {
    width: 100%;

    margin-bottom: 115px;

    align-items: center;
}

.firstPost {
    margin-bottom: 115px;
}

.secondPost {
    margin: 95px 0;
}