.card_container {
    margin-bottom: 40px;
}

.title {
    display: flex;
    align-items: center;
    padding-bottom: 8px;
    font-size: 18px;
    font-weight: bold;
}

.title img {
    margin-right: 20px;
}

.card_title {
    transition: ease all 0.5s;
}

.card_title:hover {
    transform: scaleX(1.02);
    color: #D60000;
    text-decoration: none;
}

.card_deactivated {
    color: #333;
}

.card_image {
    margin-left: 68px;
    border: none;
    box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.45);
    border-radius: 5px;
    transition: ease all 0.5s;
}

.card_image img {
    display: flex;
    width: 100%;
    border-radius: 5px;
}