body {
  margin: 0;
  padding: 0;
  font-family: 'Lucida Sans Unicode';
}

main h1 {
  font-size: 24px;
  color: #333333;
}
