.abc_filter {
    display: flex;
    justify-content: space-between;

    margin-bottom: 20px;
}

.abc_filter button {
    font-weight: bold;
    font-size: 15px;
    text-align: center;
    text-transform: capitalize;

    width: 27px;
    height: 27px;

    border-style: none;
    background-color: white;
    box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.25);

    transition: 0.6s;
    outline: none;
    cursor: pointer;
}

.abc_filter button:hover {
    color: #D60000;
    transform: scaleY(1);
    transform: scaleX(1);
}

.active-button {
    color: #D60000;
}