.footer_container {
    bottom: 0%;
}

.faixa {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #333333;
    margin-top: 5%;
    width: 100%;
    height: 45px;
}

.logos {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	max-width: 160px;
}

.logo_footer {
    font-size: 80px;
}

.logo2_footer {
    font-size: 80px;
}

a .logo2_footer {
    display: none;
}

a:hover .logo2_footer {
    display: block;
}

a:hover .logo_footer {
    display: none;
}

.footer_main {
    max-width: 1024px;
    display: flex;
    height: 90px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin: 30px auto;
    background-color: white;
}

#menu_footer {
    max-width: 1024px;
    display: flex;
    align-items: center;
    margin: 50px auto 25px auto;
}

#menu_footer div, #menu_footer a {
    font-size: 14px;
    
    justify-content: center;
}

#menu_footer a {
    color: #333;
    margin: 0 5px;
    font-size: 14px;
}


#menu_footer a:hover {
    color: #D60000;
    text-decoration: none;
}

.hr_main {
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #333;
    padding: 20px;
    
    font-size: 12px;
    color: #949494;
    text-align: center;
}

.email {
    display: flex;
    align-items: center;
}

.email svg {
    margin-right: 8px;
}

.email:hover {
    color: #D60000;
}