main {
    max-width: 1024px;
    margin: 45px auto;
}

.p_normal {
    font-family: 'Lucida Sans Unicode';
    font-size: 16px;
   
    word-break: normal;
    
    color: #1b1b1b;
   
    text-align: justify;
    line-height: 36px;
}

.p_normal:first-of-type {
    margin-top: 45px;
}

.p_bold {
    font-weight: bold;
}
