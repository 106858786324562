.slider {
    display: flex;
    padding-top: 2%;
    overflow: hidden;
    position: relative;
    height: 500px;
    width: 100%;
    margin: 20px auto;
}

.slide {
    position: relative;
    min-width: 100%;
    max-height: 95%;
    transition: 0.5s;
    padding: 0 1px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.slide img {
    max-width: 100%;
}

.subtitle {
    background-color: white;
    position: relative;
    width: 100%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    border-top: 1px solid lightgrey;
}

.subtitle p {
    font-size: 12px;
    padding: 10px 80px;
}

.seta {
    position: absolute;
    width: 10%;
    height: 100%;
    background: none;
    transition: 0.5s;
    border: none;
    outline: none;
    color: #222;
    display: flex;
    align-items: center;
    font-size: 20px;
    box-shadow: none;
}

.goLeft {
    left: 0;
    justify-content: flex-start;
}


.goRight {
    right: 0;
    justify-content: flex-end;
    transform: translateY(-23px);
}