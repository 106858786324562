.contato_main {
    max-width: 1024px;
    margin: 65px auto;
}

.cover-img {
    margin-bottom: 100px;
}

.form-title {
    color: #222;
    text-align: center;
}

.form-container {
    width: 500px;
    margin: 50px auto;
}

.form-input {
    font-family: 'Lucida Sans Unicode';
    background-color: #F9F9F9;
    box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.25);
    border: none;
    border-radius: 6px;
    width: 100%;
    padding: 10px;
    margin: 15px 0;
    resize: none;
}

.form-submit-section {
    display: flex;
    justify-content: flex-end;
    width: 520px;
}

.form-submit {
    display: flex;
    justify-content: center;
    background: none;
    border: none;
    color: #333;
    font-size: 12px;
    width: 115px;
}

.form-submit h2:hover {
    color: #D60000;
    font-weight: bold;
    transition: ease all 0.3s;
}