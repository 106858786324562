main {
    font-family: 'Lucida Sans Unicode';
    max-width: 1024px;
    margin: 115px auto;
}

.loading_all {
    max-width: 1024px;

    display: flex;
    justify-content: center;

    margin: 50px auto;
}

.abc-filter {
    margin-bottom: 115px;
}