.post_title {
    font-weight: 600;
    margin-bottom: 16px;
    font-size: 20px;
}

.post_button {
    border-radius: 15px;
    color: #333;
    padding: 10px 18px 9px 18px;
    background-color: white;
    border: 1px solid lightgray;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
    font-weight: bold;
    transform: scale(1);
    transition: ease all 0.5s;
}

.post_button:hover {
    color: #D60000;
    font-weight: bold;
    transform: scale(1.03);
    transition: ease all 0.5s;
}

.post_button_container {
    display: flex;
    align-items: center;
    justify-content: center;
}