.loading_all {
    max-width: 1024px;

    display: flex;
    justify-content: center;

    margin: 50px auto;
}

.artistas_main {
    margin: 115px auto;
    max-width: 1024px;
}

.artistas_main p {
    font-size: 15px;
}

.pagination {
    display: flex;
    justify-content: center;
}

.more-filters {
    padding-bottom: 115px;
    display: flex;
}

.MuiOutlinedInput-input {
    padding: 10px 32px 10px 10px!important;
}

.MuiOutlinedInput-adornedStart {
    margin-left: 20px;
}

.MuiOutlinedInput-root {
    border: 1px solid rgba(0, 0, 0, 0.25);
    box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.25);
}

.select-filter {
    width: 200px;
    font-weight: bold!important;
}

.search-filter {
    width: 260px;
}